import React from 'react'

import { Layout, SEO } from 'components'

const InstructionsPage = () => (
  <Layout>
    <SEO title="Instructions" />
    <h1>Sign Up Instructions</h1>
    <p>Check your email to continue.</p>
  </Layout>
)

export default InstructionsPage
